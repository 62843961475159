import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { API } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';
import { BuscarCircuitoRQ, DetalhesCircuitoRQ, PoliticaCircuitoRQ, ReservarCircuitoRQ, TarifarCircuitoRQ } from '../../model';

@Injectable()
export class CircuitoService {
  constructor(private http: HttpClient) { }

  buscarCircuitos(busca: BuscarCircuitoRQ, pagina: Number): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  detalhesCircuitos(detalhesCircuitoRQ: DetalhesCircuitoRQ): Observable<any> {
    return this.http.patch<any>(`${API}/api/Circuito/detalhes`, detalhesCircuitoRQ);
  }

  removerCircuito(circuito: any): Observable<any> {
    return this.http.delete<any>(`${API}/api/Circuito/${circuito}`);
  }

  tarifarCircuito(TarifarCircuitoRQ: TarifarCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/circuito/tarifar`, TarifarCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  politicaCircuito(politicaCircuitoRQ: PoliticaCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/Politica`, politicaCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarCircuito(ReservarCircuitoRQ: ReservarCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/Reservar`, ReservarCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  cancelarCircuito(circuitoId: number): Observable<any> {
    return this.http.patch<any>(`${API}/api/Circuito/Cancelar/${circuitoId}`, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  noitesAdicionaisCircuito(PoliticaCircuitoRQ: PoliticaCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/NoitesAdicionais`, PoliticaCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }
  
  incorporacaoAbandonoCircuito(PoliticaCircuitoRQ: PoliticaCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/IncorporacionAbandono`, PoliticaCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }
  buscarValorFinalCircuito(PoliticaCircuitoRQ: PoliticaCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/ObterValores`, PoliticaCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }
  trasladosCircuito(PoliticaCircuitoRQ: PoliticaCircuitoRQ): Observable<any> {
    return this.http.post<any>(`${API}/api/Circuito/Traslado`, PoliticaCircuitoRQ, { headers: new HttpHeaders().set('noloader', 'true') });
  }
}
