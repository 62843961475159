// FOCO
// const API = 'https://apifoco.geniusws.com.br';

// Masterop
// const API = 'https://apimasterop.geniusws.com.br';

// GENIUS
const API = 'https://api.geniusws.com.br';

// MMC
// const API = 'https://api.mmcturismo.com';
// const API = 'http://apimmc.geniusws.com.br';

// Agentetur
//  const API = 'https://api.agente.tur.br'; //// usando este;
// const API = 'http://apiagentetur.geniusws.com.br';

// Personal
// const API = 'http://apipersonal.geniusws.com.br';
// const API = 'http://api.personal.tur.br';

// CRM
// const API = 'https://apicrmfrontur.geniusws.com.br';

// AgentGO
// const API = 'https://api.agentgo.tur.br';

// Oikos
// const API = 'https://apioikos.geniusws.com.br';

// MagicWay
// const API = 'http://apimagicway.geniusws.com.br';

// ARK
// const API = 'https://api.ark.tur.br';

// POINT TUR
// const API = 'https://api.pointtur.com.br';


export const environment = {
  production: true,
  API: API,
  ambiente: "DESENVOLVIMENTO",
  THEME: "", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  USA_CASAMENTO: true,
  USA_CONTRATO: true,
  USA_CIRCUITO: true,
  USA_TURISMO: true,
  USA_PACOTE: true,
  USA_SEGURO: true,
  USA_DISNEY: true,
  USA_CARRO: true,
  USA_AEREO: true,
  USA_LOJA: true,
  USA_CASA: true,
  USA_HOTEL: true,

  //Apis dos módulos de turismo
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  TURISMO_CARRO: API,
  TURISMO: API,
  GERENCIAMENTO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: true, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: true, // Permite Gerar Lâminas no portal
  USA_BANNERS: true, //Permite o uso de Banners

  //Configurações
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  MAPBOX_ACCESSTOKEN: "",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  TAG_GOOGLE: "",
  URLSITE: ''
};
