import { Cidade } from '../endereco.model';

export class Carro {
    public Id: number;
    public selecionado: boolean;
    public Descricao: string;
    public Codigo: string;
    public ModeloCodigo: string;
    public ModeloNome: string;
    public Pax: number;
    public Bagagem: number;
    public TemAirCondicionado: boolean;
    public TransmissaoTipo: string;
    public Categoria: string;
    public Portas: number;
    public Imagem: string;
    public CondutorDocumento: string;
    public CondutorEmail: string;
    public CondutorNome: string;
    public CondutorSobrenome: string;
    public CondutorTelefone: string;
    public Status: any;
    public EnderecoOrigem: any;
    public EnderecoDevolucao: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public MoedaFechamento: any;
    public ValorListagem: any;
    public Fornecedor: any;
    public Tracao: any;
    public KmLivre: any;
    public Tipo: any;
    public EnderecoLatOrigem: any;
    public EnderecoLongOrigem: any;
    public EnderecoLatDevolucao: any;
    public EnderecoLongDevolucao: any;
    public LocalizadorIntegrador: any;
    public CodigoCliente: any;
}

export class BuscaCarroRQ {
    public LocalOrigem: any;
    public LocalDevolucao: any;
    public localOrigemTipo: any;
    public localDevolucaoTipo: any;
    public DataHoraOrigem: any;
    public DataHoraDevolucao: any;
    public Moeda: string;
    public Guid: string;
    public Rebusca: boolean;
    public AgenciaId: number;
    public Nacionalidade: string;
    public ReservaId: number;
    public Adt: number;
    public IdadesChd: number[] = [];
    public Cidade: Cidade = new Cidade();
}

export class FiltroCarro {
    public ValorMinimo: number;
    public ValorMaximo: number;
    public LocalOrigem: string = "";
    public LocalDevolucao: string = "";
    public Fornecedores: string[] = [];
}

export class LojasCarros {
    public Id: number;
    public Sigla: string = "";
    public Nome: string = "";
    public Endereco: string = "";
    public Tipo: string = "";
    public CidadeId: number;
    public display_field: string = "";
}
