
export class FormaPagamento {
  Id: number;
  Descricao: string;
  TipoPagamento: any;
  TipoPagamentoDesc: string;
  Condicoes = [];
  ValorMinimoParcela: number;
  Ativo: boolean;
  Alteracoes: any;
  Observacoes: any;
}

export class CondicaoPagamento {
  Id: number = 0;
  Descricao: string;
  FormaPagamentoId: number;
  public WebService?: any = {Descricao : ''};
  public WebServiceId: number;
}

export class CondicaoPagamento_Integrador {
    public WebService?: any = {Descricao : ''};
  }