import { Cidade } from '../endereco.model';
import { Empresa } from './empresa.model';
import { Usuario } from '../usuario.model';
import { PerfilMarkup } from '../gerenciamento/markup.model';
import { WebService } from '../gerenciamento/webservice.model';
import { FormaPagamento } from '../gerenciamento/forma-pagamento.model';


export class Agencia {
  public Id: number;

  public RazaoSocial: String;
  public NomeFantasia: String;
  public CNPJ: String;
  public InscricaoEstadual: String;
  public InscricaoMunicipal: String;
  public EmpresaId: number;
  public Consultores: any[];
  public WebSite: any;
  public Email: any;
  public TelefonePrincipal: any;
  public TelefoneSecundario: any;
  public LimiteCredito: any;
  public SaldoCredito: any;
  public Comissao: any;
  public FormaPgtoGateway: any;
  public FormaPgtoFinanciamento: any;
  public NumMaxParcelas: any;
  public UsaOffline: any;
  public Idioma: any;
  public BonusAereo: any;
  public UsaContrato: any;
  public UsaPrazoCancelamentoWs: any;
  public IgnoraMarkUpFixoHotel: any;
  public IgnoraMarkUpFixoBloqueio: any;
  public MoedaListagemId: any;
  public MoedaPagamentoId: any;
  public ListaMoedaFornecedor: any;
  public LiberaVoucherAutomatico: any;
  public Embratur: any;
  public CodigoERP: any;
  public Situacao: any;
  public DadosBancarios: string;
  public Observacoes: string;
  public ObservacoesSaldo: string;
  public Internacional: any;
  public Promotor: any;
  public TravaEmbarque: any;
  public DiasTravaEmbarque: any;
  public Latitude: string;
  public Longitude: string;

  public LimiteCartaoDeCredito: any;
  public SaldoCartaoDeCredito: any;
  public UsaSaldoCartaoDeCredito: any
  public LimiteConfirmacaoTerceiros: any;
  public SaldoConfirmacaoTerceiros: any;
  public UsaConfirmacaoTerceiros: any
  public Cidade: any = { Nome: "" };
  public GrupoEconomico: any = { Nome: "" };
  public GrupoEconomicoId: any;
  public Logo: string;
  public Empresa: any = { NomeFantasia: "" };
  public AgenciaNome: any = { NomeFantasia: "" };
  public PerfilMarkup: any = { Descricao: "" };
  public PerfilMarkupId: number;
  public Comercial?: any = { Nome: "" };
  public Meta: number;
  public LogoApresentacao: any;
  public Excluidos: WebService[];

  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
  public FormasPagamento: any;
  public AgenciaPadrao: any;

}

export class AgenciaFormaPagamento {
  Id: number;
  FormaPagamento: FormaPagamento = new FormaPagamento();
  MaxParcelas: number;
  Ativo: boolean;
  AgenciaId: number;
  FormaPagamentoId: number
}

export class Agencia_Integrador {
  public WebService?: any = { Descricao: '' };
}
