export class CampanhaEtapa {
    public Id: number
    public Descricao: string
    public Inicio: any
    public Fim: any
    public Metas : CampanhaMeta[]
}

export class CampanhaMeta {
    public Id: number
    public Nome: string
    public CampanhaEtapaId : number
    public CampanhaEtapa : any = {Descricao : ''}
    public CampanhaHoteis : CampanhaHotel[]
    public CampanhaVinculos : CampanhaVinculo[]
    public Noites : number
    public Imagem : string
}

export class CampanhaHotel {
  public Id : number
  public WebServiceId : number
  public WebService?: any = {Descricao : ''}
  public CampanhaMetaId : number
  public CodigoFornecedor : string
}

export class CampanhaVinculo {
  public Id : number
  public CampanhaMetaId : number
  public Codigo : number
  public Tipo : string
  public Nome : string
}
