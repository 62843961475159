import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';


import { LojasCarro } from '../../model';

import { API, ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';

@Injectable()
export class LojasCarroService{
  constructor(
    private http: HttpClient,
    private router: Router){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

      if (param.length > 0){
        return this.http.get<any>( `${API}/api/LojasCarro/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }else{
        return this.http.get<any>( `${API}/api/LojasCarro?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
      }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/LojasCarro/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${API}/api/LojasCarro/${id}`);
    }

    salvar(lojasCarro: LojasCarro): Observable<any>{
      if (lojasCarro.Id > 0){
        return this.http.put<any>( `${API}/api/LojasCarro/${lojasCarro.Id}`, lojasCarro);
      }else{
        return this.http.post<any>( `${API}/api/LojasCarro/`, lojasCarro);
      }

    }

    buscarPorCidade(id: number): Observable<any>{
        return this.http.get<any>( `${API}/api/LojasCarro/listaporcidade/${id}`);
    }


  }
