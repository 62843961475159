import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { UsuarioService, LoginService, ParametroService, Util,CambioService } from '../../../services';
import { Usuario,Paginacao } from '../../../model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  @Input() usuario: Usuario;

  @Input() NomeAgencia: string;

  @ViewChild("contentSenha") contentSenha: ElementRef;

  userMenuPt = [{ title: 'Perfil', target: '1' }, { title: 'Alterar Senha', target: '2' }, { title: 'Sair', target: '3' }];
  userMenu = [];

  logoempresa : string = '';
  parametroBusca: string = '';
  parametroId: any = '';

  mensagemErro = '';
  mensagemSuccesso = '';
  dadoscambio : any;
  dadosClone = [];

  paginacao: Paginacao = new Paginacao();
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserData,
    private analyticsService: AnalyticsService,
    private loginService: LoginService,
    private parametroService: ParametroService,
    private el: ElementRef,
    private router: Router,
    private usuarioService: UsuarioService,
    private cambioService: CambioService,
    private modalService: NgbModal,
    private translate: TranslateService) {
  }


  ngOnInit() {
    this.useLanguage(this.usuario.Agencia ? this.usuario.Agencia.Idioma : "pt");
    // setTimeout(() => this.tratamentosInit(), 800);
    this.tratamentosInit()
    this.buscar();
  }

  tratamentosInit() {

    this.menuService.onItemClick()
      .subscribe(item => {
        if (item.item.target == '3')
          this.logout();
        if (item.item.target == '1')
          this.perfil();
        if (item.item.target == '2')
          this.openModalSenha(this.contentSenha);
      });

    this.verificaTrocarSenha();

    let user = window.localStorage.getItem('gs57d8');

    if (user != null) {
      this.usuario = JSON.parse(atob(user));

      if (this.logoempresa == '') {
        this.logoempresa = Util.pathImagemApi(this.usuario.Agencia ? this.usuario.Agencia.Empresa.LogoInternet : this.usuario.Empresa.LogoInternet);
      }
    }
  }

  verificaTrocarSenha() {
    // console.log('Verificar trocar senha: ', this.usuario.TrocarSenha);
    if (this.usuario.TrocarSenha) {
      this.openModalSenha(this.contentSenha);
    };
    if (this.usuario.TrocarSenha == undefined)
      setTimeout(() => this.verificaTrocarSenha(), 600)
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  perfil() {
    this.router.navigate(['/perfil']);
  }

  logout() {
    this.loginService.logout();
  }


  openModalSenha(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, size: 'lg' });
  }

  senhaAlterada(dados) {
    this.logout();
  }
  buscar(){
    this.mensagemErro = '';
    // this.mensagemSuccesso = '';
    let busca = this.cambioService.buscarPorParametro(this.parametroBusca, this.paginacao.NumeroPagina);
    if (this.parametroId != undefined && this.parametroId != ''){
      this.paginacao.NumeroPagina = 1;
      busca = this.cambioService.buscarPorId(this.parametroId);
    }
    busca.subscribe(
      dados => {

        if (dados) {
          this.dadoscambio = dados.Items;

          //cria um clone do objeto cambio, para não alterar os dados do objeto original
          const dadosClone = cloneDeep(dados);

          this.dadosClone = dadosClone.Items;

          this.paginacao.NumeroPagina = dados.MetaData.NumeroPagina;
          this.paginacao.TotalItens = dados.MetaData.TotalItens;
          this.paginacao.ItensPorPagina = dados.MetaData.itensPorPagina;
          this.paginacao.TotalPaginas = dados.MetaData.TotalPaginas;
        }
        else {
          this.dadoscambio = null;
        }
      },
      erro => {
        this.dadoscambio = null;
        console.log('Erro ao Buscar');
        console.log(erro);
        this.mensagemErro = this.errorHandler(erro, 'Buscar cambio');
      },
    );
  }

    public errorHandler(erro, acao: string = null): string{

      let mensagem = 'Erro';
      if (acao !== null){
        mensagem += ' ao tentar ' + acao + ': ';
      }
      switch (erro.status){
        case 0: mensagem += 'o servidor não respondeu, tente novamente mais tarde!'; break;
        case 401: mensagem += 'você não tem autorização para executar esta ação!'; break;
        case 404: mensagem += erro.error.mensagem ; break;
        case 406: mensagem += 'a requisição não foi aceita, tente novamente!' ; break;
        case 500: mensagem += 'Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte' ; break;
        default : mensagem += erro.statusText;
      }

      return mensagem;

    }

    useLanguage(language: string) {

      this.translate.setDefaultLang(language);
      this.translate.use(language);

      const menuClone = cloneDeep(this.userMenuPt);

      //console.log(this.translate.currentLang);


      this.userMenu = menuClone.map(z => {
        this.translate.get(z.title).subscribe((translated: string) => {
            z.title = translated;
        });
        return z;
      });
  }
  // getParametroLogo() {
  //   this.parametroService.buscarPorParametro("logo-operadora", 1).subscribe(
  //     dados => {
  //       this.logooperadora = dados.Items[0];
  //       window.localStorage.setItem('SKI8gR', btoa(JSON.stringify(this.logooperadora)));
  //     },
  //     erros => {
  //       this.logooperadora = "assets/img/notfound.jpg";
  //     },
  //
  //   );
  // }

  // getLogoEmpresaUsuario() {
  //   this.usuarioService.getUserById(this.usuario.Id).subscribe(
  //     dados => {
  //
  //       this.usuario = dados.Items[0];
  //
  //       window.localStorage.setItem('gs57d8', btoa(JSON.stringify(this.usuario)));
  //
  //       if (this.logoempresa == '') {
  //         this.logoempresa = Util.pathImagemApi(this.usuario.Agencia ? this.usuario.Agencia.Empresa.LogoInternet : this.usuario.Empresa.LogoInternet);
  //       }
  //     });
  // }


}
