<div *ngIf="verLogin" class="row p-3 m-md-0 m-2" style="min-width: 43vh; min-height: 40vh; height: auto;"
  [class.black-frt]="BlackFRT" [class.arkbeds]="Arkbeds">
  <span *ngIf="!Apenas_FRT" class="col-md-12">
    <div class="col-md-12 text-center">
      <h1 id="title" class="title" style="font-family: var(--TextoTitulo) !important">{{FraseHome | translate}}</h1>
    </div>

    <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="col-md-12 m-0 pr-0">

      <div *ngIf="errors?.length > 0" class="alert alert-danger col-md-12">
        <p *ngFor="let erro of errors">{{erro}}</p>
      </div>

      <app-input errorMessage="Email obrigatório" showTip="false">
        <label>{{'Email' | translate }}:</label>
        <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.email" #email="ngModel" name="email" placeholder="{{Email}}"
          autofocus />
      </app-input>
      <app-input errorMessage="Senha Obrigatória" showTip="false">
        <label>{{'Senha' | translate}}:</label>
        <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}" required="required"
          class="form-control" [(ngModel)]="user.password" #senha="ngModel" name="password" type="password"
          placeholder="{{Senha}}" />
      </app-input>

      <div class="form-control-group accept-group col-md-12 text-center p-2 d-flex justify-content-around">
        <!-- <div> <a class="forgot-password">{{'Lembrar de mim' | translate}}</a></div> -->
        <div class="col-md" *ngIf="urlSite.length > 0"> <a *ngIf="urlSite.length > 0" class="forgot-password"
            href="{{urlSite}}">{{'Registre-se' | translate}}</a>
        </div>
        <div class="col-md"> <a target="_blank"
            *ngIf="enderecoapi.length > 0 && enderecoapi == 'https://api.comprefacil.tur.br'" class="forgot-password"
            href="https://api.comprefacil.tur.br/uploads/Termos_de_Uso_e_Política_de_Privacidade_FRT.pdf">{{'Política de
            privacidade' | translate}}</a></div>
        <div class="col-md"><a class="forgot-password" href="" (click)="toggleRecuperarSenha($event)">{{'Recuperar
            senha' | translate}}</a></div>
      </div>

      <button (click)="login()" [disabled]="form.invalid" class="btn btn-success btn-block">{{'Login' |
        translate}}</button>
    </form>
  </span>

  <span *ngIf="Apenas_FRT" class="login-frt col-md-12">
    <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="col-md-12 m-0 pr-0">

      <span style="background-color: transparent;" *ngIf="errors?.length > 0" class="alert alert-danger col-md-12">
        <p *ngFor="let erro of errors">{{erro}}</p>
      </span>

      <div class="col-md-12 logo-frt">
        <img src="../../../assets/img/FdaFRT.png" alt="F da FRT">
      </div>

      <h4 class="titulo">{{FraseHome}}</h4>

      <app-input errorMessage="Email obrigatório" showTip="false">
        <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}" required="required"
          class="form-control input-form" [(ngModel)]="user.email" #email="ngModel" name="email" placeholder="{{Email}}"
          autofocus />
      </app-input>
      <app-input errorMessage="Senha Obrigatória" showTip="false">
        <input nbInput status="{{(senha.invalid && (senha.dirty || senha.touched)) ? 'danger' :''}}" required="required"
          class="form-control input-form" [(ngModel)]="user.password" #senha="ngModel" name="password" type="password"
          placeholder="{{Senha}}" />
      </app-input>

      <button (click)="login()" [disabled]="form.invalid" class="btn btn-success btn-block">{{'Login' |
        translate}}</button>

      <div class="politica-e-senha">
        <div class="col-12"><span class="forgot-password">Desejo</span><a class="forgot-password" href=""
            (click)="toggleRecuperarSenha($event)">recuperar senha</a></div>
        <div class="col-12 mt-md-0 mt-3"><span class="forgot-password">{{'Política de privacidade' | translate}}</span><a
            target="_blank" class="forgot-password"
            href="https://api.comprefacil.tur.br/uploads/Termos_de_Uso_e_Política_de_Privacidade_FRT.pdf">Download</a>
        </div>
      </div>

    </form>
  </span>
</div>

<div *ngIf="verRecuperarSenha" class="row m-md-0 m-2" [class.black-frt]="BlackFRT == true">
  <span *ngIf="!Apenas_FRT" class="col-md-12">
    <div class="col-md-12 text-center">
      <h2 id="title" class="title" style="font-family: var(--TextoTitulo) !important">{{'Recuperação de Senha' |
        translate}}</h2>
    </div>

    <form class="col-md-12 m-0 pr-0" #formRecuperar="ngForm" aria-labelledby="title" role="form" novalidate
      (submit)="recuperarSenha($event)">

      <div *ngIf="errorSenha" class="alert alert-danger text-center">
        <p *ngFor="let erro of errorSenha">{{erro}}</p>
      </div>
      <div *ngIf="successSenha" class="alert alert-success text-center">
        <p>{{successSenha}}</p>
      </div>

      <app-input errorMessage="Email obrigatório" showTip="false">
        <label>Email:</label>
        <input nbInput required="required" class="form-control" [(ngModel)]="emailSenha" name="emailSenha"
          placeholder="Email" autofocus />
      </app-input>

      <div class="form-control-group accept-group">
        <button type="button" (click)="recuperarSenha()" [disabled]="formRecuperar.invalid"
          class="btn btn-success">{{'Recuperar senha' | translate}}</button>
        <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)" class="btn btn-danger">{{
          'Cancelar'
          | translate}}</button>
        <button type="button" *ngIf="Sair" (click)="toggleRecuperarSenha($event)" class="btn btn-primary">{{'Sair' |
          translate}}</button>
      </div>

    </form>
  </span>

  <span *ngIf="Apenas_FRT" class="login-frt col-md-12">
    <form #form="ngForm" aria-labelledby="title" role="form" novalidate class="col-md-12 m-0 pr-0 ">

      <span style="background-color: transparent;" *ngIf="errors?.length > 0" class="alert alert-danger col-md-12">
        <p *ngFor="let erro of errors">{{erro}}</p>
      </span>

      <div class="logo-frt">
        <img src="../../../assets/img/FdaFRT.png" alt="F da FRT">
      </div>

      <h4 class="titulo recuperacao" style="color: gray;">{{RecuperarSenha}}</h4>

      <app-input errorMessage="Email obrigatório" showTip="false">
        <input nbInput status="{{(email.invalid && (email.dirty || email.touched)) ? 'danger' :''}}" required="required"
          class="form-control input-form" [(ngModel)]="user.email" #email="ngModel" name="email" placeholder="{{Email}}"
          autofocus />
      </app-input>

      <button (click)="login()" [disabled]="form.invalid" class="btn btn-success btn-block">{{'Recuperar Senha' |
        translate}}</button>
      <button type="button" *ngIf="Cancelar" (click)="toggleRecuperarSenha($event)" class="btn btn-danger btn-block">{{'Cancelar' |
        translate}}</button>

      <div class="politica-e-senha">
        <div class="col-12"><span class="forgot-password">Por gentileza, verifique seu e-mail</span></div>
      </div>

    </form>
  </span>
</div>
