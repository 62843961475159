import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';



import { CampanhaMeta, CampanhaHotel, CampanhaVinculo} from '../../model';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';

@Injectable()
export class CampanhaMetaService{

  constructor(private http: HttpClient){}


    buscarPorParametro(param: string, pagina: Number): Observable<any>{
      const headers = new HttpHeaders().set('noloader', 't');

        if (param.length > 0)
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaMeta/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
        else
        {
          return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaMeta?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, {headers: headers});
        }
    }

    buscarPorId(id: number): Observable<any>{
        return this.http.get<any>( `${GERENCIAMENTO}/api/CampanhaMeta/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any>{
      return this.http.delete<any>( `${GERENCIAMENTO}/api/CampanhaMeta/${id}`);
    }

    salvar(campanhameta: CampanhaMeta): Observable<any>{
      if (campanhameta.Id > 0){
        return this.http.put<any>( `${GERENCIAMENTO}/api/CampanhaMeta/${campanhameta.Id}`, campanhameta);
      }else{
        return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaMeta/`, campanhameta);
      }

    }

    vinculaCampanhaHotel (campanhaHotel: CampanhaHotel ): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaMeta/vincularhotel`, campanhaHotel);
    }

    desvincularCampanhaHotel (campanhaHotel: CampanhaHotel): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaMeta/desvincularhotel`, campanhaHotel);
    }

    vinculaCampanhaVinculo (campanhaVinculo: CampanhaVinculo): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaMeta/vincularregiao`, campanhaVinculo);
    }

    desvincularCampanhaVinculo (campanhaVinculo: CampanhaVinculo): Observable<any> {
      return this.http.post<any>( `${GERENCIAMENTO}/api/CampanhaMeta/desvincularregiao`, campanhaVinculo);
    }

    uploadImagem(arquivo: any, id: number): Observable<any>{

      const url = `${GERENCIAMENTO}/api/campanhameta/imagem/${id}/`;

      const headers = new HttpHeaders().append('noloader', 'noloader');
      const formData = new FormData();
      formData.append('Arquivo', arquivo);

      return this.http.post(url, formData, {headers : headers});

    }

  }
