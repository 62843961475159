
export class LojasCarro {
  public Id: number;
  public Cidade?: any;
  public CidadeId: number;
  public Sigla : string;
  public Nome : string;
  public Endereco : string;
  public display_field: string = "";
}
