import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscaHotelRQ } from '../../model';

import { TURISMO_HOTEL } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';

@Injectable()
export class HotelService {
    constructor(private http: HttpClient) { }

    buscarHotel(busca: BuscaHotelRQ, pagina: Number): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    removerHotel(hotel: any): Observable<any> {
        return this.http.delete<any>(`${TURISMO_HOTEL}/api/Hotel/${hotel.Id}`);
    }

    buscarPolitica(hotel: any): Observable<any> {
        return this.http.patch<any>(`${TURISMO_HOTEL}/api/hotel/politica/${hotel.ReservaId}/${hotel.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    reservarHotel(politica: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/reservar`, politica, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    cancelarHotel(hotel: any): Observable<any> {
        return this.http.patch<any>(`${TURISMO_HOTEL}/api/hotel/cancelar/${hotel.ReservaId}/${hotel.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    atualizarStatusHotel(hotel: any): Observable<any> {
        return this.http.patch<any>(`${TURISMO_HOTEL}/api/hotel/atualizar/${hotel.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    detalhesHotel(hotel: any): Observable<any> {
        return this.http.patch<any>(`${TURISMO_HOTEL}/api/hotel/detalhes`, hotel, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    buscarHotelPorId(id): Observable<any> {
        return this.http.get<any>(`${TURISMO_HOTEL}/api/Hotel/${id}`, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    alterarStatus(alteracao: any): Observable<any> {
        return this.http.put<any>(`${TURISMO_HOTEL}/api/hotel/${alteracao.Id}`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relCheckinPorData(checkInInicial: any, checkInFinal: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/checkinpordata`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

    relHoteis(checkInInicial: any, checkInFinal: any, webservice: any,empresa: any, agencia: any,consultor: any,cidadeid: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/hoteisvendidos`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal,WebService: webservice,Empresa: empresa,Agencia: agencia,Consultor: consultor, Cidade: cidadeid }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

     relHotelReservadoNaoPago(checkInInicial: any, checkInFinal: any, webservice: any,empresa: any, agencia: any,consultor: any,cidadeid: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/hotelreservadonaopago`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal,WebService: webservice,Empresa: empresa,Agencia: agencia,Consultor: consultor, Cidade: cidadeid }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

     relStatusReservasHoteis(checkInInicial: any, checkInFinal: any): Observable<any> {
        return this.http.post<any>(`${TURISMO_HOTEL}/api/Hotel/statusreservashoteis`, { CheckInInicial: checkInInicial, CheckInFinal: checkInFinal }, { headers: new HttpHeaders().set('noloader', 'true') });
    }

}
