import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule, ModuleWithProviders} from '@angular/core';
import { ThemeModule } from '../@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {InputComponent} from './input/input.component';
import { NbDatepickerModule} from '@nebular/theme';
// import { TypeaheadComponent } from './typeahead/typeahead.component';
import { EstrelaComponent } from './estrela/estrela.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { ImagemComponent } from './imagem/imagem.component';
import { LogComponent } from './log/log.component';
import { ChaveValorCadastroComponent } from './chave-valor-cadastro/chave-valor-cadastro.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { ArquivoComponent } from './arquivo/arquivo.component';
import { TranslateModule } from "@ngx-translate/core";



@NgModule({

  declarations: [InputComponent, TypeaheadComponent,  EstrelaComponent, PaginacaoComponent, ConfirmacaoComponent, ImagemComponent, LogComponent, ChaveValorCadastroComponent, ArquivoComponent ],
  imports: [CommonModule, TranslateModule,
    FormsModule,NbDatepickerModule
  //  ReactiveFormsModule,

  ],
  exports: [
    InputComponent, EstrelaComponent, ImagemComponent, LogComponent,
    CommonModule, FormsModule, PaginacaoComponent, ConfirmacaoComponent,
    ChaveValorCadastroComponent, TypeaheadComponent, ArquivoComponent
    //ReactiveFormsModule // Estes para que quem improtar o modulo, nao precise REimportar
  ],

})

export class SharedModule{
  static forRoot(): ModuleWithProviders{
    return {
      ngModule: SharedModule,
      providers: [],

    };
  }

}
